// import logo from './logo.svg';

import "./App.css";
import { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import {
  HeaderMenu,
  HeaderMenuOverlay,
  Languages,
  Packs,
  Repositories,
} from "./components";
import languages from "./data/languages.json";
import { getRepositoryHistoriesJson } from "./data/repositoryHistories";
import { convertRepositoryData, formatDate } from "./converters";

function App() {
  const location = useLocation();
  const [selectedLang, setSelectedLang] = useState();
  const [selectedRepository, setSelectedRepository] = useState();
  const [createdTime, setCreatedTime] = useState();
  const [packsData, setPacksData] = useState();
  const [repositories, setRepositories] = useState([]);
  const languageNames = languages.map((lang) =>
    lang.name === "Unknown" ? "ALL" : lang.name
  );
  const changeSelectedLang = useCallback(
    (langName) => {
      const selectLang = languageNames.find(
        (lang) => lang.toLowerCase() === langName.toLowerCase()
      );
      setSelectedLang(selectLang);
    },
    [languageNames]
  );

  useEffect(() => {
    const hashName = location.hash
      ? decodeURIComponent(location.hash.replace("#", "")).toLowerCase()
      : "";
    const isSearchName = languageNames.some((name) => {
      return name.toLowerCase() === hashName;
    });
    changeSelectedLang(isSearchName ? hashName : languageNames[0]);
  }, [location, languageNames, changeSelectedLang]);

  useEffect(() => {
    if (!selectedLang) {
      return;
    }

    const allData = convertRepositoryData(
      getRepositoryHistoriesJson(selectedLang)
    );

    if (allData) {
      setCreatedTime(new Date(allData.created_date));
      setPacksData(allData.data);
      setRepositories(allData.data.items);
    } else {
      setCreatedTime(null);
      setPacksData(null);
      setRepositories(null);
    }
  }, [selectedLang]);

  return (
    <section className="gs-wrapper">
      <header className="gs-header">
        <div className="gs-header-title">
          <h1>GitHub★TopStars</h1>
          <p>Ranking and Visualizing star of repositories on GitHub.</p>
        </div>
        <HeaderMenu />
      </header>
      <main className="gs-content">
        <Languages langNames={languageNames} selectedLang={selectedLang} />
        <div className="gs-chart">
          {packsData ? (
            <Packs
              data={packsData}
              selectedRepositoryId={selectedRepository?.id}
            />
          ) : (
            <div className="gs-nodata">
              <p>Sorry, it has not repositories...</p>
            </div>
          )}
        </div>
        <aside className="gs-side">
          <div className="gs-side-title">
            <h2>Repositories/Stars</h2>
            {createdTime && (
              <p className="gs-side-date">
                as of{" "}
                <time className="gs-repodate">{formatDate(createdTime)}</time>
              </p>
            )}
          </div>
          {repositories && repositories.length && (
            <Repositories
              data={repositories}
              onHover={(d) => setSelectedRepository(d)}
            />
          )}
        </aside>
      </main>
      <footer className="gs-footer">
        <small>
          Created by
          <a href="https://twitter.com/cawpea" target="_blank" rel="noreferrer">
            @cawpea
          </a>
        </small>
      </footer>
      <HeaderMenuOverlay />
      <div className="gs-loading">
        <p>Loading...</p>
      </div>
    </section>
  );
}

export default App;
