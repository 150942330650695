import * as d3 from "d3";
import { useRef, useEffect, useCallback } from "react";

export const Packs = ({ data, selectedRepositoryId }) => {
  let packRoot = useRef(null);
  let packElements = useRef(null);

  const svgWrapRef = useRef(null);
  const svgRef = useRef(null);

  const getSvgData = useCallback(() => {
    return d3
      .hierarchy(data)
      .sum(function (d) {
        return d.stargazers_count;
      })
      .sort(function (a, b) {
        return b.data.stargazers_count - a.data.stargazers_count;
      });
  }, [data]);

  const getSvgSize = () => {
    const $svg = svgRef.current;
    const $svgWrap = svgWrapRef.current;

    return {
      width: $svg.clientWidth || $svgWrap.clientWidth,
      height: $svg.clientHeight || $svgWrap.clientHeight,
    };
  };

  const drawPacks = useCallback(() => {
    const $svg = d3.select(svgRef.current);
    const svgSize = getSvgSize();
    // カラーを準備
    const color = d3.interpolateLab("#FFF", "#1ec3ff");
    const maxStarNum = d3.max(data.items, function (d) {
      return d.stargazers_count;
    });

    $svg.selectAll("g").remove();

    // TODO: あとで対応
    // _this.$repoList.selectAll("li").remove();

    const pack = d3.pack().size([svgSize.width, svgSize.height]);
    const $packRoot = $svg.append("g");
    const $packElements = $packRoot
      .selectAll("g")
      .data(pack(getSvgData()).descendants(), function (d) {
        return d.data.id;
      })
      .enter()
      .append("g")
      .attr("transform", function (d, i) {
        return "translate(" + d.x + ", " + d.y + ")";
      });

    // NOTE: 円を作成
    $packElements
      .append("circle")
      .attr("r", 0)
      .transition()
      .duration((d, i) => {
        return d.depth * 1000 + 500;
      })
      .delay((d) => {
        return (Math.random() * 500) << 0;
      })
      .attr("r", (d) => {
        return d.r;
      })
      .style("fill", (d, i) => {
        if (d.depth === 0) {
          return "#032d3d";
        }
        return color(d.value / maxStarNum);
      });

    $packElements
      .append("text")
      .style("opacity", 0)
      .transition()
      .duration(3000)
      .style("opacity", 1.0)
      .attr("dy", "-0.3em")
      .text((d) => {
        if (d.depth === 1) {
          return d.data.full_name.split("/")[0];
        }
        return null;
      });

    $packElements
      .append("text")
      .style("opacity", 0)
      .transition()
      .duration(3000)
      .style("opacity", 1.0)
      .attr("dy", "0.7em")
      .text((d) => {
        if (d.depth === 1) {
          return d.data.full_name.split("/")[1];
        }
        return null;
      });

    packRoot.current = $packRoot;
    packElements.current = $packElements;
  }, [data, getSvgData]);

  useEffect(() => {
    drawPacks();
  }, [data, drawPacks]);

  useEffect(() => {
    if (!packElements.current) {
      return;
    }
    if (selectedRepositoryId) {
      packElements.current.attr("class", function (d) {
        if (d.data.id && d.data.id === parseInt(selectedRepositoryId)) {
          return "is-focus";
        } else {
          return "is-blur";
        }
      });
    } else {
      packElements.current.attr("class", function () {
        return "";
      });
    }
  }, [selectedRepositoryId, packElements]);

  useEffect(() => {
    window.addEventListener("resize", drawPacks);
    return () => {
      window.removeEventListener("resize", drawPacks);
    };
  });

  return (
    <div className="gs-chart-inner" ref={svgWrapRef}>
      <svg ref={svgRef}></svg>
    </div>
  );
};
