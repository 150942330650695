import { useEffect, useRef } from "react";
import * as d3 from "d3";

export const Repositories = ({ data, onHover }) => {
  const repoListRef = useRef(null);

  useEffect(() => {
    const $repoList = d3.select(repoListRef.current);

    $repoList.selectAll("li").remove();

    const $repoItems = $repoList
      .selectAll("li")
      .data(data)
      .enter()
      .append("li")
      .attr("class", function (d, i) {
        var rank = i <= 2 ? " rank" + (i + 1) : "";
        return "gs-repolist-item" + rank;
      });

    const $link = $repoItems
      .append("a")
      .attr("href", function (d) {
        return d.html_url;
      })
      .attr("target", "_blank")
      .attr("data-id", function (d) {
        return d.id;
      })
      .on("mouseover", (d) => {
        onHover(d);
      })
      .on("mouseout", (d) => {
        onHover(null);
      });

    $link
      .append("span")
      .attr("class", "gs-repolist-name")
      .text(function (d) {
        return d.full_name;
      });

    const $stars = $link
      .append("span")
      .attr("class", "gs-repolist-star")
      .text(function (d) {
        d._stargazers_count = 0;
        return d._stargazers_count;
      });

    const timerId = setInterval(() => {
      let isCountAll = true;
      $stars.text(function (d) {
        if (d._stargazers_count < d.stargazers_count) {
          isCountAll = false;
          d._stargazers_count += (Math.random() * 500) << 0;
          return d._stargazers_count;
        }
        return d.stargazers_count;
      });

      if (isCountAll) {
        clearInterval(timerId);
      }
    }, 1);
  }, [data, onHover]);

  return (
    <div className="gs-repolist-wrap">
      <ol className="gs-repolist" ref={repoListRef}></ol>
    </div>
  );
};
