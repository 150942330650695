const fillInZero = (num) => {
  return ("0" + num).slice(-2);
};

export const formatDate = (date) => {
  let dateStr =
    fillInZero(date.getFullYear()) +
    "/" +
    fillInZero(date.getMonth() + 1) +
    "/" +
    fillInZero(date.getDate());
  dateStr +=
    " " + fillInZero(date.getHours()) + ":" + fillInZero(date.getMinutes());
  return dateStr;
};
