export const Languages = ({ langNames, selectedLang }) => {
  return (
    <section className="gs-nav">
      <div className="gs-nav-title">
        <h2>Languages</h2>
      </div>
      <div className="gs-langlist-wrap">
        <ul className="gs-langlist" role="menu" aria-labelledby="menu-trigger">
          {langNames.map((langName) => (
            <li
              role="presentation"
              key={langName}
              className={
                selectedLang &&
                selectedLang.toLowerCase() === langName.toLowerCase()
                  ? "is-selected"
                  : ""
              }
            >
              <a
                href={`#${encodeURIComponent(langName).toLowerCase()}`}
                role="menuitem"
              >
                {langName}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};
