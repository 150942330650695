import actionscript from "./actionscript.json";
import all from "./all.json";
import arduino from "./arduino.json";
import asp from "./asp";
import assembly from "./assembly.json";
import c from "./c.json";
import csharp from "./c#.json";
import cplusplus from "./c++.json";
import clojure from "./clojure.json";
import coffeescript from "./coffeescript.json";
import commonlisp from "./commonlisp.json";
import d from "./d.json";
import dart from "./dart.json";
import elixir from "./elixir.json";
import emacslisp from "./emacslisp.json";
import erlang from "./erlang.json";
import fsharp from "./f#.json";
import go from "./go.json";
import groovy from "./groovy.json";
import haskell from "./haskell.json";
import html from "./html.json";
import java from "./java.json";
import javascript from "./javascript.json";
import lua from "./lua.json";
import matlab from "./matlab.json";
import objectivec from "./objective-c.json";
import ocaml from "./ocaml.json";
import perl from "./perl.json";
import php from "./php.json";
import powershell from "./powershell.json";
import processing from "./processing.json";
import puppet from "./puppet.json";
import python from "./python.json";
import r from "./r.json";
import ruby from "./ruby.json";
import rust from "./rust.json";
import scala from "./scala.json";
import scheme from "./scheme.json";
import shell from "./shell.json";
import sql from "./sql.json";
import swift from "./swift.json";
import tex from "./tex.json";
import typescript from "./typescript.json";
import viml from "./viml.json";
import visualbasic from "./visualbasic.json";
import xslt from "./xslt.json";

const repositoryHistories = {
  actionscript,
  all,
  arduino,
  asp,
  assembly,
  c,
  "c#": csharp,
  "c++": cplusplus,
  clojure,
  coffeescript,
  commonlisp,
  d,
  dart,
  elixir,
  emacslisp,
  erlang,
  "f#": fsharp,
  go,
  groovy,
  haskell,
  html,
  java,
  javascript,
  lua,
  matlab,
  "objective-c": objectivec,
  ocaml,
  perl,
  php,
  powershell,
  processing,
  puppet,
  python,
  r,
  ruby,
  rust,
  scala,
  scheme,
  shell,
  sql,
  swift,
  tex,
  typescript,
  viml,
  visualbasic,
  xslt,
};

export const getRepositoryHistoriesJson = (lang) => {
  const lowerName = lang.toLowerCase();
  switch (lowerName) {
    default:
      return repositoryHistories[lowerName];
  }
};
