export const convertRepositoryData = (repositoryHistories) => {
  if (!repositoryHistories || !repositoryHistories.length) {
    return;
  }

  const repoData = {};
  const newestCreatedDate = repositoryHistories[0].created_at;
  const repositories = [];

  repositoryHistories.forEach((repositoryHistory) => {
    var convertedRepository = {};
    convertedRepository.id = repositoryHistory.id;
    convertedRepository.full_name = repositoryHistory.repository_name;
    convertedRepository.html_url = repositoryHistory.repository_html_url;
    convertedRepository.stargazers_count = repositoryHistory.stargazers_count;
    repositories.push(convertedRepository);
  });

  repoData.created_date = newestCreatedDate;
  repoData.data = {};
  repoData.data.items = repositories;
  repoData.data.children = repositories;
  return repoData;
};
