import { useEffect, useState } from "react";

export const HeaderMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const $body = document.querySelector("body");
    if (isOpen) {
      $body.classList.add("is-menu-open");
    } else {
      $body.classList.remove("is-menu-open");
    }
  }, [isOpen]);

  return (
    <button
      type="button"
      id="menu-trigger"
      className="gs-header-menu"
      aria-label={isOpen ? "メニューを閉じる" : "メニューを開く"}
      aria-haspopup="true"
      aria-controls="menu"
      onClick={() => setIsOpen(!isOpen)}
    >
      <p className="gs-header-menuopen">
        <span></span>
      </p>
      <p className="gs-header-menuclose"></p>
    </button>
  );
};

export const HeaderMenuOverlay = () => {
  const closeMenu = () => {
    const $body = document.querySelector("body");
    $body.classList.remove("is-menu-open");
  };
  return <div className="gs-nav-bg" onClick={closeMenu}></div>;
};
